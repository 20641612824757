.completed-tests-list {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.test-item {
  width: 200px;
  height: 100px;
  margin: 10px;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  transition: background-color 0.3s;
  border: solid;
  background-color: rgba(22, 48, 32, 0.7);

  &:hover {
    background-color: rgba(22, 48, 32, 1);
    cursor: pointer;
    transform: scale(0.95);
  }
}
