.active {
    background-color: #4CAF50; /* Updated active background color */
}

nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #163020; /* Dark blue background */
    color: white;
    position: sticky;
    top: 0;
    z-index: 5;
}

nav .title {
    font-size: 1.5rem;
    margin: 1rem;
    font-weight: bold;
    text-decoration: none;
    color: #4CAF50; /* Updated title color */
}

nav ul {
    display: flex;
}

nav ul li {
    list-style: none;
}

nav ul li a {
    display: block;
    text-decoration: none;
    color: white;
    padding: 0.5rem;
    margin: 0 0.5rem;
    border-radius: 0.5rem;
    transition: transform 0.3s ease;
}

nav ul li a:not(.active):hover {
    background-color: #4CAF50;
    transform: scale(1.1);
}

nav .menu {
    display: none;
    position: absolute;
    top: 0.75rem;
    right: 0.5rem;
    flex-direction: column;
    justify-content: space-between;
    width: 2.25rem;
    height: 2rem;
}

nav .menu span {
    height: 0.4rem;
    width: 100%;
    background-color: #4CAF50; 
    border-radius: 0.2rem;
}

@media (max-width: 800px) {
    nav .menu {
        display: flex;
    }

    nav {
        flex-direction: column;
        align-items: flex-start;
    }

    nav ul {
        display: none;
        flex-direction: column;
        width: 100%;
        margin-bottom: 0.25rem;
    }

    nav ul.open {
        display: flex;
    }

    nav ul li {
        width: 100%;
        text-align: center;
    }

    nav ul li a {
        margin: 0.2rem 0.5rem;
    }
    
}