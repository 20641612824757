.App {
  text-align: center;
}

.answer-list-item {
  list-style-type: none;
  transition: transform 0.3s ease; /* Add a smooth transition effect */
}

.answer-list-item:hover {
  transform: scale(1.1); /* Increase the scale on hover (zoom effect) */
}

/* ProfilePage.css */

.profile-container {
  background-color: rgba(22, 48, 32, 0.7); /* Light gray background */
  color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Optional: Add a subtle box shadow */
  height: "100vh";
  // margin: 20px;
}

.profile-details {
  margin-top: 15px;
}

.title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.image-container {
  margin-bottom: 20px;
}

img {
  max-width: 100%;
  height: auto;
  padding: 5px;
}

.description {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 10px;
}
/* Add any additional styling as needed */

.custom-box {
  width: 350px;
  height: 500px;
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: rgba(255, 192, 203, 0.7); /* Default background color */
  color: white;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.custom-box-answer {
  width: 350px;
  height: 500px;
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: rgba(22, 48, 32, 0.7); /* Default background color */
  color: white;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.selector {
  cursor: pointer;
  display: inline-block;
  // position: relative;
  padding-right: 15px;
}

.custom-box-answer-no-img {
  width: 350px;
  height: 500px;
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: rgba(22, 48, 32, 0.7); /* Default background color */
  color: white;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.custom-box-answer-no-img:hover {
  transform: scale(0.95);
}

.custom-box-answer:hover {
  transform: scale(0.95);
}

.custom-box:hover {
  background-color: rgba(255, 192, 203, 1); /* Hover background color */
  cursor: pointer;
  transform: scale(0.95);
}

.past-date {
  background-color: rgba(22, 48, 32, 0.7); /* Background color for past date */
}

.past-date:hover {
  background-color: rgba(
    22,
    48,
    32,
    1
  ); /* Hover background color for past date */
}

.imagine-exemplar {
  width: 300px;
  height: 300px;
}

.background-container {
  min-height: 100vh;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.container-exemplare {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.btn-ok {
  display: inline-block;
  padding: 6px 20px;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  color: #fff;
  border-radius: 5px;
  background-color: #163020;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s;
  border: none;
}

.btn-ok:hover {
  background-color: #0c1811;
  color: white;
}

.imagine-raspuns {
  width: 120px; /* Adjust width for mobile */
  height: 120px; /* Adjust height for mobile */
}

.error-container {
  width: 600px;
  height: 300px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(22, 48, 32, 1); /* Green */
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
  font-family: Arial, sans-serif;
  font-size: 18px;
}

:root {
  --height: 100%; /* Set default height to 100% of viewport height */
}

.custom-background {
  background-repeat: no-repeat;
  background-attachment: fixed;
  height: var(--height);
  background-size: cover;
}

/* Background and overlay */
.custom-background-home {
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-family: Arial, sans-serif;
}

.overlay {
  background-color: rgba(0, 0, 0, 0.6); /* Dark overlay for readability */
  padding: 40px;
  border-radius: 8px;
  max-width: 800px;
  margin: 20px;
}

/* Main Title */
.main-title {
  font-size: 2rem;
  text-align: center;
  margin-bottom: 20px;
  font-weight: bold;
}

/* Text Content */
.intro-text {
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 20px;
  text-align: justify;
}

/* Section Titles */
.section-title {
  font-size: 1.5rem;
  margin-top: 30px;
  margin-bottom: 10px;
  font-weight: bold;
  border-bottom: 2px solid #fff;
  padding-bottom: 5px;
}

/* Link Styling */
.link {
  color: #a8e063;
  text-decoration: underline;
}

.link:hover {
  color: #f6d365;
}

/* Responsive Design */
@media (max-width: 768px) {
  .main-title {
    font-size: 1.5rem;
  }

  .intro-text {
    font-size: 0.9rem;
  }

  .overlay {
    padding: 20px;
  }
}

.introduction {
  background-color: rgba(22, 48, 32, 0.7);
  color: white;
  position: relative; /* Position relative to its parent */
  padding: 20px; /* Optional: Add padding as needed */
  height: 100%;
}

.container {
  position: relative;
  /* Ensure positioning context for absolutely positioned children */
}

.chapter-selector {
  position: sticky;
  // top: 7vh;
  z-index: 1;
}

a {
  color: #ff0000;
}

@media only screen and (max-width: 800px) {
  .introduction {
    background-color: rgba(22, 48, 32, 0.7);
    color: white;
    // width: 80%;
    position: relative; /* Position relative to its parent */
    overflow-y: auto; /* Make the element scrollable vertically if needed */
    padding: 20px; /* Optional: Add padding as needed */
    // box-sizing: border-box; /* Ensure padding is included in the width */
    // left: 50%;
    // transform: translateX(-50%);
  }

  .chapter-selector {
    position: sticky;
    // top: 8vh;
    z-index: 1;
  }

  .custom-background {
    background-attachment: scroll;
    background-size: cover;
  }
  .imagine-exemplar {
    width: 200px; /* Adjust width for mobile */
    height: 200px; /* Adjust height for mobile */
  }

  .custom-box {
    width: 250px; /* Adjust width for mobile */
    height: 550px; /* Adjust height for mobile */
  }

  .custom-box-answer-no-img {
    width: 210px; /* Adjust width for mobile */
    height: 480px; /* Adjust height for mobile */
  }
  .custom-box-answer {
    width: 210px; /* Adjust width for mobile */
    height: 500px; /* Adjust height for mobile */
  }
}
